import { useTheme } from '@mui/material';
import { addBreadcrumb, captureException, captureMessage } from '@sentry/react';
import { Uppy } from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import type { Track } from 'core';
import type { ComponentType } from 'react';
import { useMemo } from 'react';
import type { Issue } from 'validata';
import { useNumberFlag } from '../../hooks/use-flag.js';
import type { FileUploadProps } from './types.js';
import { FileUploadView } from './view.js';

const controller =
  (View: typeof FileUploadView): ComponentType<FileUploadProps> =>
  ({ endpoint, onFailure, onSuccess, ...props }) => {
    const theme = useTheme();
    const maxSizeMb = useNumberFlag('upload.max-size-mb', 10);

    const uppy = useMemo<Uppy>(() => {
      const up = new Uppy({
        allowMultipleUploadBatches: false,
        restrictions: {
          maxFileSize: maxSizeMb * 2 ** 20,
        },
        debug: false,
      });
      up.use(XHRUpload, {
        endpoint,
      });
      up.on('upload-error', (file, err) => {
        if ('request' in err) {
          const request = err.request as XMLHttpRequest;
          if (request.status === 400 && request.responseText.startsWith('{')) {
            const response = JSON.parse(request.responseText) as {
              issues?: Issue[];
            };
            if (response.issues) {
              captureMessage('Invalid upload file', {
                level: 'warning',
                extra: { issues: response.issues, maxSizeMb },
              });
              onFailure?.(response.issues);
              return;
            }
          }
          captureMessage('Upload file error', {
            level: 'warning',
            extra: {
              maxSizeMb,
              status: request.status,
              response: request.responseText,
            },
          });
          return;
        }
        captureException(err);
      });
      up.on('complete', (result) => {
        if (result.successful.length) {
          const successful = result.successful[0];
          const { track, success, matchingEvents } = successful.response
            ?.body[0] as Track.UploadResponse;

          addBreadcrumb({
            data: { track, success, matchingEvents },
            level: 'info',
            message: 'Track upload success',
          });
          (success && onSuccess?.(track, matchingEvents)) || onFailure?.();
        } else {
          captureMessage('Track upload failure', {
            level: 'error',
            extra: { maxSizeMb, result },
          });
          onFailure?.();
        }
      });
      return up;
    }, [endpoint, onFailure, onSuccess, maxSizeMb]);

    return View({
      darkMode: theme.palette.mode === 'dark',
      ...props,
      uppy,
    });
  };

export default controller(FileUploadView);

import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import uri from 'uri-tag';
import { TrackUploadView } from './view.js';

const Controller: FC = () => {
  const navigate = useNavigate();

  return TrackUploadView({
    onCreateSuccess: (track) => {
      navigate(uri`/track/${track._id}`, { replace: true });
    },
    onCancelClick: () => navigate(-1),
  });
};

export default Controller;

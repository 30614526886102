import type { ComponentType } from 'react';
import { useState } from 'react';
import type { TrackUploadProps } from './types.js';
import { TrackUploadView } from './view.js';

const controller =
  (View: typeof TrackUploadView): ComponentType<TrackUploadProps> =>
  (props) => {
    const [error, setError] = useState<string>();

    return View({
      ...props,
      error,
      onFailure: (issues) => {
        if (issues) {
          const messages: string[] = [];
          issues.forEach((issue) => {
            switch (issue.reason) {
              case 'unable-to-find-start-finish': {
                messages.push('no start or finish location could be found');
                break;
              }
              case 'invalid-header': {
                // fit
                messages.push('the header was invalid');
                break;
              }
              case 'invalid-crc': {
                // fit
                messages.push('the CRC was invalid');
                break;
              }
              case 'unsupported-schema': {
                // gpx, tcx
                messages.push('XML was was an unsupported schema');
                break;
              }
              case 'expected-time': {
                // gpx
                messages.push(
                  'GPX file did not contain time information (this may be because it was downloaded from someone elses Strava activity)',
                );
                break;
              }
              default: {
                messages.push(issue.reason);
                break;
              }
            }
          });
          setError(messages.join(', '));
        }
        props.onFailure?.();
      },
    });
  };

export default controller(TrackUploadView);

import { ArrowBack as CancelIcon } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import type { Track } from 'core';
import type { FC } from 'react';
import { PageTitle } from '../../components/page-title/page-title.js';
import { TrackUpload } from '../../components/track-upload/component.js';

interface Props {
  onCreateSuccess: (track: Track.Type) => void;
  onCancelClick: () => void;
}

export const TrackUploadView: FC<Props> = ({
  onCreateSuccess,
  onCancelClick,
}) => (
  <PageTitle title="Feed">
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h2">Upload Track</Typography>
        <TrackUpload onSuccess={onCreateSuccess} />
        <Button
          variant="text"
          fullWidth
          startIcon={<CancelIcon />}
          onClick={onCancelClick}
          sx={{ mt: 1 }}
        >
          Cancel
        </Button>
      </Stack>
    </Container>
  </PageTitle>
);

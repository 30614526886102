import { Alert, Stack, Typography } from '@mui/material';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import type { FC } from 'react';
import { FileUpload } from '../file-upload/component.js';
import type { TrackUploadViewProps } from './types.js';

export const TrackUploadView: FC<TrackUploadViewProps> = ({
  error,
  onFailure,
  onSuccess,
}) => (
  <Stack spacing={2}>
    <Typography variant="body1">
      You can upload your track as a FIT, TCX or GPX file, which is a type of
      file that contains GPS data recorded during your run, and compare your
      route with others who have completed the same course.
    </Typography>
    <Typography variant="body2" color="text.secondary" fontSize="0.7rem">
      A Garmin FIT (Flexible and Interoperable Data Transfer) file is a type of
      file format used by Garmin devices to store fitness and activity data. It
      is a binary file format that contains a variety of data fields, including
      time, GPS coordinates, heart rate, speed, distance, and other metrics
      collected during an activity. It is also available to be exported from som
      other platforms.
    </Typography>
    <Typography variant="body2" color="text.secondary" fontSize="0.7rem">
      A TCX (Training Center XML) file is a type of file format used by many
      GPS-enabled fitness devices, such as Garmin watches or cycling computers.
      TCX files contain data such as GPS coordinates, heart rate, and other
      metrics that are recorded during a workout or training session.
    </Typography>
    <Typography variant="body2" color="text.secondary" fontSize="0.7rem">
      A GPX (GPS Exchange Format) file is a file format used for exchanging GPS
      data between different applications and devices. GPX files can contain
      data such as GPS coordinates, elevation, and timestamps.
    </Typography>
    <Alert severity="warning">
      GPX files do not contain distance information. If you are interested in
      distance, tracks under 2 hours will have distance estimated. However, for
      more accuracy, it is recommended you upload FIT or TCX files, or import
      from Strava.
    </Alert>

    {!error ? (
      <FileUpload
        width="100%"
        endpoint="/api/v1/track/upload"
        onFailure={onFailure}
        onSuccess={onSuccess}
      />
    ) : (
      <Alert severity="error">{error}</Alert>
    )}
  </Stack>
);

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Dashboard } from '@uppy/react';
import type { FC } from 'react';
import type { FileUploadViewProps } from './types.js';

export const FileUploadView: FC<FileUploadViewProps> = ({
  darkMode,
  height = 160,
  uppy,
  width,
}) => (
  <Dashboard
    uppy={uppy}
    theme={darkMode ? 'dark' : 'light'}
    width={width}
    height={height}
    showSelectedFiles={false}
    fileManagerSelectionType="files"
    disableThumbnailGenerator={true}
  />
);
